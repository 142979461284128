import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Icon } from '../../components/Icon';
import Loader from '../../components/Loader/Loader';
import formatTime from '../../helpers/formatTime';
import getDate from '../../helpers/getDate';
import tempCacl from '../../helpers/tempCacl';
import { deleteWorkouts, getWorkoutsItem } from '../../redux/actions/workouts';
import ActiveItem from '../ProfilePage/components/Statistic/ActiveItem/ActivityItem';
import Map from './Map/Map';
import PhotoItem from './PhotoItem/PhotoItem';

const ActivityItem = ()=>{
    const [ready,setReady]=useState(false);
    const [iconType,setIconType]=useState('');
    const [iconTypeSmile,setIconTypeSmile]=useState('');
    const [form,setForm]=useState({});
    const currentId =useParams().id;
    const dispatch = useDispatch();
    const { listId } = useSelector((state) => state);
    const { currentUser } = useSelector((state) => state.currentUser);
    const navigate = useNavigate();

       

    const initFunc =()=>{
        dispatch(getWorkoutsItem({id:currentId})).then(res=>{
            if (res.weatherIcon == "01"){
                setIconType("ic_sun")
            }else if (res.weatherIcon == "02"){
                setIconType( "ic_sun")
            }else if( res.weatherIcon == "03"){
                setIconType( "ic_cloud")
            }else if( res.weatherIcon == "04"){
                setIconType( "ic_cloud")
            }else if (res.weatherIcon == "09"){
                setIconType( "ic_cloud_rain")
            }else if( res.weatherIcon == "10"){
                setIconType( "ic_cloud_rain")
            }else if (res.weatherIcon == "11"){
                setIconType( "ic_cloud_rain")
            }else if (res.weatherIcon == "13"){
                setIconType("ic_snowflake")
            }


            if (res.weatherIcon == 4){
                setIconTypeSmile("Ic_smile_one")
            }
            else if (res.weatherIcon == 3){
                setIconTypeSmile("Ic_smile_two")
            }else if (res.weatherIcon == 2){
                setIconTypeSmile( "Ic_smile_three")
            }else if( res.weatherIcon == 1){
                setIconTypeSmile( "Ic_smile_four")
            }else if( res.weatherIcon == 0){
                setIconTypeSmile( "Ic_smile_four")
            }else{
                setIconTypeSmile( "Ic_smile_four")
            }
            setForm(res);
            setReady(true);
        })
    };

    const deleteClick=()=>{
        dispatch(deleteWorkouts({id:currentId})).then(res=>{
            navigate(`/personalPage/${currentUser.id}/statistic`)
        })
    }
    

    useEffect(() => {
        if(!ready){
            initFunc();
        }
    }, [ready]);
    
    if(ready){
    return(<>
        <h1>{form.title || form.address || 'Не зазначено'} <Icon type={iconTypeSmile}></Icon>
            <div className="headerClubSub">
                Тип: <span>{listId.list[form.type]||'В приміщенні'}</span>
                <span className="currentStatusDivider"></span>
                Дата:
                {form.startsAt && form.finishesAt ?
                 <span>{getDate(form.startsAt*1000,'dd/mmmm/yyyy hh:mm',' ')} - {getDate(form.finishesAt*1000,'hh:mm',':')}</span>
                :
                <span>
                    {getDate(form.createdAt,'dd/mmmm/yyyy hh:mm',' ')} 
                </span>
                }
            </div>
        </h1>

        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={() => navigate(`/personalPage/${currentUser.id}/statistic`)}
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
            </button>
            <button
                className="secondaryButtonIconTextDefault"
                onClick={deleteClick}
              >
                <svg>
                  <use href="/images/icon24Trash.svg#icon24" />
                </svg>
                Видалити
              </button>

          </div>
        </div>
        <div className="pageContentHeaderDivider" />
        { form.activityType !== "eb1f23b9-a872-43ef-8462-a513504af111" ?
        <div>
            <h2>Маршрут</h2>
            <div className='flexDirect'>
                <div className='itemIndex'>
                    <ActiveItem item={form}></ActiveItem>
                </div>
                <div className='itemIndex2'>
                    <Map el={form.routePoints}></Map>
                </div>
            </div>
            <h2>Статистика</h2>
            <div className="statisticList">
                <div className="statisticListItem">
                    <div className="statisticItem">
                        <div>
                            <Icon type='Calories'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {form.calories || '--'} <span>ккал</span>  
                            </div>
                            <div className="statisticItemLabel">
                                Активних калорій
                            </div>
                        </div>
                    </div>
                   {<div className="statisticItem">
                        <div>
                            <Icon type='Calories'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {form.passiveCalories || '--'} <span>ккал</span>  
                            </div>
                            <div className="statisticItemLabel">
                                Пасивних калорій
                            </div>
                        </div>
                    </div>}
                    {<div className="statisticItem">
                        <div>
                            <Icon type='Calories'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {form.totalCalories || '--'} <span>ккал</span>  
                            </div>
                            <div className="statisticItemLabel">
                                Всього калорій
                            </div>
                        </div>
                    </div>}

                    <div className="statisticItem">
                        <div>
                            <Icon type='AvarageHeartRate'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                            {form.minBpm || '--'} <span>уд/хв</span> 
                            </div>
                            <div className="statisticItemLabel">
                                Мінімальний пульс
                            </div>
                        </div>
                    </div>

                    <div className="statisticItem">
                        <div>
                            <Icon type='AvarageHeartRate'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                            {form.bpm || '--'} <span>уд/хв</span> 
                            </div>
                            <div className="statisticItemLabel">
                                Середній пульс
                            </div>
                        </div>
                    </div>
                    <div className="statisticItem">
                        <div>
                            <Icon type='AvarageHeartRate'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                            {form.maxBpm || '--'} <span>уд/хв</span> 
                            </div>
                            <div className="statisticItemLabel">
                                Максимальний пульс
                            </div>
                        </div>
                    </div>

                    <div className="statisticItem">
                        <div>
                            <Icon type='Alarm'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                            {(form.time && formatTime(form.time)) || '--'} 
                            </div>
                            <div className="statisticItemLabel">
                                Трив. активностей
                            </div>
                        </div>
                    </div>

                   
                </div>
                <div className="statisticListItem">
                    <div className="statisticItem">
                        <div>
                            <Icon type='Distance'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {(form.distance && (form.distance/1000)?.toFixed(2)) || '--'} <span>км</span>  
                            </div>
                            <div className="statisticItemLabel">
                                Дистанція
                            </div>
                        </div>
                    </div>
                    <div className="statisticItem">
                        <div>
                        <Icon type='avarageTemp'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {tempCacl(form.temp)}
                                <span>хв/км</span>  
                            </div>
                            <div className="statisticItemLabel">
                                Середній темп
                            </div>
                        </div>
                    </div>

                    <div className="statisticItem">
                        <div>
                            <Icon type='Speed'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                            {form.speed || '--'} <span>км/год</span>  
                            </div>

                            <div className="statisticItemLabel">
                                Середня швидкість
                            </div>
                        </div>
                    </div>

                    <div className="statisticItem">
                        <div>
                            <Icon type='Speed'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                            {form.speedMax || '--'} <span>км/год</span>  
                            </div>
                            <div className="statisticItemLabel">
                                Максимальна швидкість
                            </div>
                        </div>
                    </div>

                    <div className="statisticItem">
                        <div>
                            <Icon type='Hight_Up'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {form.hight || '--'} <span>м</span> 
                            </div>
                            <div className="statisticItemLabel">
                                Підйом вверх
                            </div>
                        </div>
                    </div>

                    <div className="statisticItem">
                        <div>
                            <Icon type='Hight_Down'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {form.altitude_min || '--'} <span>м</span> 
                            </div>
                            <div className="statisticItemLabel">
                                Зниження висоти
                            </div>
                        </div>
                    </div>     
                    <div className="statisticItem">
                        <div>
                            <Icon type={iconType}></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {form.temperature || '--'}  <span>℃</span> 
                            </div>
                            <div className="statisticItemLabel">
                            Погода
                            </div>
                        </div>
                    </div> 
                </div>               
            </div>

            {form.photos?.length>0 && <><h2>Фото маршруту</h2>
                <div className='flexDirect'>
                {form.photos.map((el,index)=>{
                    return(
                        <PhotoItem el={el}  index={index}/>
                    )
                })}
                </div>
            </>}

        </div>:
        <div>
        <h2>Статистика</h2>
        <div className='flexDirect'>
            <div className=''>
                <ActiveItem item={form}></ActiveItem>
            </div>
            <div className='itemIndex2'>
            <div className="statisticList">
                <div className="statisticListItem">
                    <div className="statisticItem">
                        <div>
                            <Icon type='Alarm'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                            {(form.time && formatTime(form.time)) || '--'} 
                            </div>
                            <div className="statisticItemLabel">
                                Трив. активностей
                            </div>
                        </div>
                    </div>
                    <div className="statisticItem">
                        <div>
                            <Icon type='AvarageHeartRate'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                            {form.maxBpm || '--'} <span>уд/хв</span> 
                            </div>
                            <div className="statisticItemLabel">
                                Максимальний пульс
                            </div>
                        </div>
                    </div>
                    
                    <div className="statisticItem">
                        <div>
                            <Icon type='AvarageHeartRate'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                            {form.minBpm || '--'} <span>уд/хв</span> 
                            </div>
                            <div className="statisticItemLabel">
                                Мінімальний пульс
                            </div>
                        </div>
                    </div>
                    
                    <div className="statisticItem">
                        <div>
                            <Icon type='AvarageHeartRate'></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                            {form.bpm || '--'} <span>уд/хв</span> 
                            </div>
                            <div className="statisticItemLabel">
                                Середній пульс
                            </div>
                        </div>
                    </div>
                </div>
                <div className="statisticListItem">
                    <div className="statisticItem">
                            <div>
                                <Icon type='Calories'></Icon>
                            </div>
                            <div>
                                <div className="statisticItemText">
                                    {form.calories || '--'} <span>ккал</span>  
                                </div>
                                <div className="statisticItemLabel">
                                    Витрачено калорій
                                </div>
                            </div>
                        </div>

                    <div className="statisticItem">
                        <div>
                            <Icon type={'steps'}></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {form.steps || '--'} 
                            </div>
                            <div className="statisticItemLabel">
                            Кроків
                            </div>
                        </div>
                    </div>
                    <div className="statisticItem">
                        <div>
                            <Icon type={iconType}></Icon>
                        </div>
                        <div>
                            <div className="statisticItemText">
                                {form.temperature || '--'}  <span>℃</span> 
                            </div>
                            <div className="statisticItemLabel">
                            Погода
                            </div>
                        </div>
                    </div>
                </div>              
            </div>
        </div>
        </div>
       
        

        {form.photos?.length>0 && <><h2>Фото тренування</h2>
            <div className='flexDirect'>
            {form.photos.map((el,index)=>{
                return(
                    <PhotoItem el={el}  index={index}/>
                )
            })}
            </div>
        </>}

    </div>
        }
    
    </>)}
    else{
        return(
            <Loader/>
        )
    }
}


export default ActivityItem;