import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Navigation, Pagination, EffectFade } from "swiper";
import { Swiper as Slider, SwiperSlide as Slide } from "swiper/react";
import { getNews } from "../../redux/actions/news";
import { getFcCreator } from "../../redux/actions/fc";
import { getParksAll } from "../../redux/actions/parks";
import useFilterArray from "../../hooks/filterArray";

import "swiper/css";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import MobileDownload from "../../components/MobileDownload/MobileDownload";
import getDate from "../../helpers/getDate";
import PetalsBlock from "../MainPage/PetalsBlock/PetalsBlock";
import { qrReadVideo } from "../../redux/actions/video";
import { Icon } from "../../components/Icon";
import ActionBlock from "./ActionBlock/ActionBlock";
import EventsBlock from "./EventsBlock/EventsBlock";
import ParksBlock from "./ParksBlock/ParksBlock";
import NewsBlock from "./NewsBlock/NewsBlock";
import SocialNetworks from "./SocialNetworks/SocialNetworks";

const StartPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { news, coordinates } = useSelector((state) => state);
  const { currentUser } = useSelector((state) => state.currentUser);
  const [ready, setReady] = useState(false);
  const [action, setAction] = useState("default");
  const { fullList } = useSelector((state) => state);
  const [categ, setCateg] = useState("");
  const [dif, setDif] = useState("");
  const [sub, setSub] = useState("");
  const [type, setType] = useState("official");
  let regionIdData = "AA";
  const [className, setClassName] = useState({
    "01": "normal",
    "02": "normal",
    "03": "normal",
    "04": "normal",
    "05": "normal",
    "06": "normal",
  });
  const backClick = (event) => {
    event.preventDefault();
    if (action === "category") {
      setAction("default");
      setCateg(event.currentTarget.id);
    } else if (action === "difficulty" && sub) {
      setAction("category");
    } else {
      setAction("default");
      setSub("");
    }
  };
  
  const clickHandler = (event) => {
    event.preventDefault();
      setCateg(event.currentTarget.id);
      setAction("category");
  };
  const setDifClick = (e) => {
    let difficult = "";
    if (e.currentTarget.accessKey) {
      setDif(e.currentTarget.accessKey);
      difficult = e.currentTarget.accessKey;
    }
    let data={
      regionId:regionIdData,
    }
    if(currentUser && currentUser.id){
      data.userId= currentUser.id;
    }else{
      data.userId='';
    }
    dispatch(qrReadVideo(data))
      .then((res) => {
        if (type == "official") {
          navigate(
            `/video/${regionIdData}/${categ}/${difficult}/${sub ? sub : "0"}/0`
          );
        } else if (type == "commercial") {
          navigate(
            `/videouser/${regionIdData}/${categ}/${difficult}/${sub ? sub : "0"}/0`
          );
        } else {
          navigate(
            `/video/${regionIdData}/${categ}/${difficult}/${sub ? sub : "0"}/0/1`
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const subcategoryClick = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (
      event &&
      event.currentTarget.id &&
      event.currentTarget.id.length === 3
    ) {
      setSub(event.currentTarget.id);
      setAction("difficulty");
    } else {
      setSub("");
      setAction("difficulty");
    }
  };
  const [fcList, setFcList] = useState([]);
  const { filteredData } = useFilterArray(news);

  const apStartPlay = () => {
    let elem = document.getElementById("apPlayButton");
    elem.style.display = "none";
    let playelem = document.getElementById("apVideoPlayer");
    playelem.play();
  };

  const initialFunction = () => {
    let data = {};
    data.filter = {};
    data.limit = 5;
    data.sort = { publishedAt: "desc" };
    dispatch(getFcCreator({ limit: 8,id:currentUser?.id })).then((res) => {
      setFcList([...res.items.userIsHead,...res.items.userIsMember]);
      setReady(true);
    });
  };


  useEffect(() => {
    if (!ready) {
      initialFunction();
      // getEventsList();
    }
  }, []);

  const userClick =(e)=>{
    navigate('/personalPage/'+e.target.id)
  }
  
  if (!ready) {
    return <Loader />;
  }
  if(ready){

 
  return (
    <>
      {/* <div dangerouslySetInnerHTML={{ __html: func(string, option) }}></div> */}
      <EventsBlock/>
      <section className="main-section main-events">
        <div className="headline" style={{width:'100%'}}>
          <h2 style={{textAlign:'center'}}>Тренуйся з відомими українцями!</h2>
        </div>
        <div className="petalStartPageBlock">
          <div className="petalLeft1 petalLeft">
                  <button
              className="primaryLargeButtonIconTextDefault cusTom"
                    onClick={() => {
                      navigate(`/video/${regionIdData}/07/04/0/0`);
                    }}
                  >
                    <div>
                      <svg>
                        <use href="/images/icon24Rocket.svg#icon24" />
                      </svg>
                    </div>
                    Розминка
                  </button>
                  <button
                    className="primaryLargeButtonIconTextDefault cusTom"
                    onClick={() => {
                      navigate(`/video/${regionIdData}/08/04/0/0`);
                    }}
                  >
                    <div>
                      <svg>
                        <use href="/images/icon24Power.svg#icon24" />
                      </svg>
                    </div>
                    Заминка
                  </button>
                  <button
                    className="primaryLargeButtonIconTextDefault cusTom"
                    onClick={() => {
                      navigate(`/video/${regionIdData}/09/04/0/0`);
                    }}
                  >
                    <div>
                      <svg>
                        <use href="/images/icon24Medical.svg#icon24" />
                      </svg>
                    </div>
                    Поради лікаря
                  </button>
                </div>
                <PetalsBlock
                  action={action}
                  fullList={fullList}
                  category={categ}
                  difficulty={dif}
                  className={className}
                  setClassName={setClassName}
                  backClick={backClick}
                  clickHandler={clickHandler}
                  setDifClick={setDifClick}
                  subcategoryClick={subcategoryClick}
                ></PetalsBlock>
                <div className="petalLeft2 petalLeft">
                  <button
              className="primaryLargeButtonIconTextDefault cusTom"
                    onClick={() => {
                      navigate(`/video/${regionIdData}/07/04/0/0`);
                    }}
                  >
                    <div>
                      <svg>
                        <use href="/images/icon24Rocket.svg#icon24" />
                      </svg>
                    </div>
                    Розминка
                  </button>
                  <button
                    className="primaryLargeButtonIconTextDefault cusTom"
                    onClick={() => {
                      navigate(`/video/${regionIdData}/08/04/0/0`);
                    }}
                  >
                    <div>
                      <svg>
                        <use href="/images/icon24Power.svg#icon24" />
                      </svg>
                    </div>
                    Заминка
                  </button>
                  <button
                    className="primaryLargeButtonIconTextDefault cusTom"
                    onClick={() => {
                      navigate(`/video/${regionIdData}/09/04/0/0`);
                    }}
                  >
                    <div>
                      <svg>
                        <use href="/images/icon24Medical.svg#icon24" />
                      </svg>
                    </div>
                    Поради лікаря
                  </button>
                </div>
                <div className="petalLeft">
                  <button
              className="primaryLargeButtonIconTextDefault cus cusTom"
                    onClick={() => {
                      navigate(`/mainpage/official`);
                    }}
                  >
                    <div>
                     <Icon type='filmStrip'></Icon>
                    </div>
                    Офіційні відео
                  </button>
                  <button
                    className="primaryLargeButtonIconTextDefault cus cusTom"
                    onClick={() => {
                      navigate(`/mainpage/commercial`);
                    }}
                  >
                    <div>
                     <Icon type='usersThree'></Icon>
                    </div>
                    Відео користувачів
                  </button>
                  <button
                    className="primaryLargeButtonIconTextDefault cus cusTom"
                    onClick={() => {
                      navigate(`/mainpage/tro`);
                    }}
                  >
                     <div>
                     <Icon type='sword'></Icon>
                    </div>
                    Відео ТРО
                  </button>
                </div>
        </div>

      </section>
      <ParksBlock/>
      <NewsBlock/>              
        {fcList && fcList.length !== 0 && (
        <section className="main-section main-clubs">
          <>
            <div className="headline">
              <h2>Мої клуби:</h2>
            </div>
            <Slider
              modules={[Navigation, Pagination, EffectFade]}
              navigation
              pagination={{ clickable: true }}
              slidesPerView={1}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 3,
                },
              }}
              spaceBetween={24}
            >
              {fcList.map((el, index) => (
                <Slide key={index}>
                <div className="startPageItem"
                  style={{
                    backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.6) 10%, rgba(0,0,0,0) 70%), url('${
                      (el.logoUrl !== null &&
                        el.logoUrl.length !== 0 &&
                        el.logoUrl) ||
                      "https://ap.sportforall.gov.ua/api/v1/uploads/b34604xc-02qb-46bb-9e91-fc3ffrb92w41"
                      }')`,
                  }}>
                      <div className="leftsideInfoStartPage">
                        <div className="leftsideInfoStartPageHeader"  onClick={() => navigate(`/fc/${el.id}/about`)}> {el.name}</div>
                        <div className="leftsideInfoStartPageSubText">{el.address ?<span>{el.address}</span> :<span>Учасників: {el.memberAmount}</span>}</div>
                      </div>
                     
                  </div>
                </Slide>
              ))}
            </Slider>
          </>
      </section>        )}
     <ActionBlock></ActionBlock>
     {/* <div className="main-section">
      <div className="headline" style={{paddingBottom:'0px'}}>
          <h2>Ми в соціальних мережах</h2>
        </div>
     </div> */}

     {/* <SocialNetworks></SocialNetworks> */}
      {/* <section style={{margin:'40px 0 0'}}  className="main-section main-clubs">
          <div className="headline">
              <h2>Мета існування Активних парків та цього ресурсу</h2>
            </div>
        <div className="forthSection">
        
        <div className="forthSectionText">
          <p>
            За останніми даними, лише 17% українців займаються регулярною руховою активністю - бракує часу або немає можливостей відвідувати спортивні зали. У ЄС цей показник щонайменше вдвічі вищий. Низька фізична активність населення - один з головних факторів, які впливають на захворюваність та тривалість життя. 
          </p>
          <p>
            Щоб подвоїти показник залучення населення до масових видів спорту і тим самим зміцнити імунітет та здоров'я українців, була започаткована програма "Здорова Україна" та проект "Активні парки". Ми прагнемо залучити до активних видів дозвілля та спорту понад 4 мільйони українців, і з часом та з вашою допомогою цей показник буде лише зростати!
          </p>
          <button className="primaryButtonIconTextDefault" id="/map" onClick={() => navigate("/map")}>
            <svg>
              <use href="/images/icon24MapPin.svg#icon24" />
            </svg>
            Переглянути локації
          </button>
        </div>
        <div className="videoPlayerContainer">
          <video
            preload="metadata"
            id="apVideoPlayer"
            width="100%"
            height="100%"
            controls="controls"
            poster="https://ap.sportforall.gov.ua/api/v1/uploads/c0bfd1f9-fc5e-425e-b60a-1a8924c81da2"
          >
            <source
              src="https://media.sportforall.gov.ua/media/wH5pIBOJ.mp4"
              type="video/mp4"
              media="all and (max-width: 720px)"
              data-quality="SD"
            />
            <source
              src="https://media.sportforall.gov.ua/media/oHPVnX03.mp4"
              type="video/mp4"
              data-quality="HD"
            />
          </video>
          <svg id="apPlayButton" onClick={apStartPlay}>
            <use href="/images/icon80PlayCircle.svg#icon80" />
          </svg>
        </div>
      </div>
      </section> */}
      <div className="storeSection">
            <div className="storeText">
              <h1 className="storeHeaderActive">Активні парки у твоєму смартфоні</h1>
              <h2 className="storeHeader">Встановлюй наш зручний додаток на свій телефон</h2>
              <div className="storeButtonCollection">
                <button className='storeButton' onClick={()=>window.open('https://apps.apple.com/us/app/id1661291870')} style={{background:`url(${'/images/appStore.png'})`}}></button>
                <button className='storeButton' onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.technodreams.activeparks')} style={{background:`url(${'/images/googlePlay.png'})`}}></button>
              </div>
            </div>
            <div className="storeImage" style={{backgroundImage:`url(${'/images/Phones.png'})`}}>
            </div>
            <div className="storeImage storeImage2" style={{backgroundImage:`url(${'/images/Kap.png'})`}}>
            </div>
           
        </div>
      {/* <MobileDownload/> */}
    </>
  )};
};

export default StartPage;
